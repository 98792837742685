import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const mainGridFn = /* @__PURE__ */ createRecipe('mainGrid', {}, [])

const mainGridVariantMap = {}

const mainGridVariantKeys = Object.keys(mainGridVariantMap)

export const mainGrid = /* @__PURE__ */ Object.assign(memo(mainGridFn.recipeFn), {
  __recipe__: true,
  __name__: 'mainGrid',
  __getCompoundVariantCss__: mainGridFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: mainGridVariantKeys,
  variantMap: mainGridVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, mainGridVariantKeys)
  },
  getVariantProps: mainGridFn.getVariantProps,
})