'use client';

import classnames from 'classnames';
import React, { FC } from 'react';
import { css } from 'styled-system/css';
import { ButtonVariantProps, button } from 'styled-system/recipes';

type As<Props = any> = React.ElementType<Props>;

interface IButtonProps extends Record<string, unknown> {
	children: React.ReactNode;
	as?: As;
	className?: string;
	icon?: React.ReactNode;
}

export const Button: FC<IButtonProps & ButtonVariantProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	as: As = 'button',
	className,
	variant,
	size,
	icon,
	...buttonProps
}) => (
	<As className={classnames(className, button({ variant, size }))} {...buttonProps}>
		{icon && <span className={css({ mr: 2 })}>{icon}</span>}
		{children}
	</As>
);
