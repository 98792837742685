'use client';

import { Button } from '@/components/core/Button/Button';
import { useTranslation } from '@app/i18n/client';
import { signOut } from 'next-auth/react';
import { FC } from 'react';

export const LogoutButton: FC = () => {
	const { t } = useTranslation();

	const handleSignOut = async () => {
		await signOut({ callbackUrl: '/' });
	};

	return (
		<Button type="submit" variant="ghost" size="sm" onClick={handleSignOut}>
			{t('global.logout')}
		</Button>
	);
};
